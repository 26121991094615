
import { Options, Vue } from 'vue-class-component';
import { GarmentClient, GarmentDefaultDataClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Garment extends Vue {

    items: OM.TextIdentifier[] = [];

    created() {
        GarmentClient.getAllConfigurations()
        .then(x => {
            this.items = x;
        })
    }

    editGarment(garmentId: string) {
        if(garmentId == '0')
            this.$router.push('/garment/' + garmentId);
        else {
            GarmentClient.checkIsFootwear(garmentId)
            .then(x => {
                if(x)
                    this.$router.push('/footwear/' + garmentId);
                else
                    this.$router.push('/garment/' + garmentId);
            })
        }
    }

    defaultData(garmentId: string){
        GarmentDefaultDataClient.checkExisting(garmentId)
        .then(x => {
            this.$router.push('/garmentDefaultData/editbaseinformation/' + x);
        })
    }

}
